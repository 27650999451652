import React from 'react'
import { Translate } from 'react-localize-redux'
import GoalkeeperImage from 'assets/images/groups-goalkeeper.svg'
import DefenderImage from 'assets/images/groups-defender.svg'
import MidfieldImage from 'assets/images/groups-midfield.svg' // @fixme: image size is wrong
import StrikerImage from 'assets/images/groups-striker.svg'

export const PLAYER = "PLAYER"
export const PLAYER_CAMEL_CASE = "Player"

export const INVITE_ROLE = [
  { role:"relative", title: 'Relative' },
  { role:"player", title: <Translate id="events.table-player" /> },
]
export const PLAYER_POSITIONS = [
  {
    value: 'goalkeeper',
    title: <Translate id="player.goalkeeper" />,
    pluralValue: 'goalkeepers',
    image: GoalkeeperImage,
  },
  {
    value: 'defender',
    title: <Translate id="player.defender" />,
    pluralValue: 'defenders',
    image: DefenderImage,
  },
  {
    value: 'midfield',
    title: <Translate id="player.midfield" />,
    pluralValue: 'midfielders',
    image: MidfieldImage,
  },
  {
    value: 'striker',
    title: <Translate id="player.striker" />,
    pluralValue: 'strikers',
    image: StrikerImage,
  },
]
export const SKILLS_NAMES = [
  {
    id: 'pace',
    title: <Translate id="team.pace" />,
  },
  {
    id: 'shoot',
    title: <Translate id="team.shoot" />,
  },
  {
    id: 'dribbling',
    title: <Translate id="team.dribbling" />,
  },
  {
    id: 'passing',
    title: <Translate id="team.passing" />,
  },
  {
    id: 'defending',
    title: <Translate id="team.defending" />,
  },
  {
    id: 'physique',
    title: <Translate id="team.physique" />,
  },
]

export const PLAYER_SUB_POSITIONS = {
  midfield: [
    {
      value: 'RW',
      title: <Translate id="player.RW" />,
    },
    {
      value: 'LW',
      title: <Translate id="player.LW" />,
    },
    {
      value: 'DM',
      title: <Translate id="player.DM" />,
    },
    {
      value: 'CM',
      title: <Translate id="player.CM" />,
    },
    {
      value: 'AM',
      title: <Translate id="player.AM" />,
    },
  ],
  goalkeeper: [
    {
      value: 'GK',
      title: <Translate id="player.GK" />,
    },
  ],
  defender: [
    {
      value: 'LB',
      title: <Translate id="player.LB" />,
    },
    {
      value: 'CB',
      title: <Translate id="player.CB" />,
    },
    // {
    //   value: 'SW',
    //   title: <Translate id="player.SW" />,
    // },
    {
      value: 'RB',
      title: <Translate id="player.RB" />,
    },
  ],
  striker: [
    // {
    //   value: 'ST',
    //   title: <Translate id="player.ST" />,
    // },
    {
      value: 'LF',
      title: <Translate id="player.LF" />,
    },
    {
      value: 'RF',
      title: <Translate id="player.RF" />,
    },
    {
      value: 'CF',
      title: <Translate id="player.CF" />,
    },
  ],
}

export const FOOT_OPTIONS = [
  { value: 'l', title: <Translate id="player.l-foot" /> },
  { value: 'r', title: <Translate id="player.r-foot" /> },
]

export const GENDER_OPTIONS = [
  { value: 'm', title: <Translate id="form.male" /> },
  { value: 'f', title: <Translate id="form.female" /> },
]

export const BASIC_STAT_COLUMNS = [
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: <Translate id="events.table-player" />,
  },

  {
    id: 'number',
    numeric: true,
    disablePadding: false,
    label: <Translate id="form.number" />,
  },
  {
    id: 'statistics.total_trainings',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.total-trainings" />,
  },

  {
    id: 'statistics.trainings_attended',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.player-training-participation" />,
  }
]

export const PAID_STAT_COLUMNS = [
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: <Translate id="events.table-player" />,
  },

  {
    id: 'number',
    numeric: true,
    disablePadding: false,
    label: <Translate id="form.number" />,
  },
  {
    id: 'statistics.total_trainings',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.total-trainings" />,
  },

  {
    id: 'statistics.trainings_attended',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.player-training-participation" />,
  },
  {
    id: 'statistics.total_matches',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.total-matches" />,
  },
  {
    id: 'statistics.matches_attended',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.player-match-participation" />,
  },
  {
    id: 'statistics.total_minute_played',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.total-match-time" />,
  },
  {
    id: 'statistics.goals_scored',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.goal-scored" />,
  },
  {
    id: 'statistics.goals_assisted',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.goal-assisted" />,
  },
  {
    id: 'statistics.ycard',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.yellow-cards" />,
  }, 
  {
    id: 'statistics.yrcard',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.y-r-cards" />,
  },
  {
    id: 'statistics.rcard',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.red-cards" />,
  }
]

export const ATTENDANCE_COLUMNS = [
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: <Translate id="events.table-player" />,
  },

  {
    id: 'events_attended',
    numeric: true,
    disablePadding: false,
    label: <Translate id="player.participation" />,
  }
]

export const PRO_SHARE_OPTIONS = ['Cover Page (Default)', 'Player Skills', 'Game Journal']

export const ELITE_SHARE_OPTIONS = ['Cover Page (Default)', 'Player Skills', 'Game Journal', 'Video', 'Fitness & medical']

export const playerFilterCategories = [
  {
    key: 'all',
    displayValue: <Translate id="matches.all-players" />
  },
  {
    key: 'goalkeeper',
    displayValue: <Translate id="team.goalies" />
  },
  {
    key: 'defender',
    displayValue: <Translate id="team.defender" />
  },
  {
    key: 'midfield',
    displayValue: <Translate id="team.midfield" />
  },
  {
    key: 'striker',
    displayValue: <Translate id="team.striker" />
  },
]