import React, { useCallback, useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Box, Button, Grid, TablePagination } from '@material-ui/core'
import SearchBar from 'material-ui-search-bar'
import { debounce } from 'lodash'
import { DrillCard, LoadingSpinner, NotFound } from 'components'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import Source from './components/Source'
import {
  duplicateDrill,
  fetchClubAgeClasses,
  fetchClubDrillCategories,
  fetchClubDrills,
  fetchFavouriteDrills,
  fetchTeamDrills,
  updateFavoriteDrill,
} from './api'
import CategoriesDialog from './components/CategoriesDialog'
import { MenuPaper, useStyles } from './styles'
import PropTypes from 'constants/propTypes'
import { DRILL_EDIT_ROUTE } from 'constants/routes'

const ActivitiesContainer = ({
  history,
  team,
  language,
  match: {
    // for now using seasonId from match params because seasons in store have a different structure
    params: { seasonId },
  },
  withPhaseMenu,
  showAddToTrainingButton,
  onPhaseSelect,
  showDrillActions,
}) => {
  const classes = useStyles()

  const { drill_providers: drillProviders } = team
  const [providers, setProviders] = useState([])
  const [ageClasses, setAgeClasses] = useState([])
  const [selectedAgeClass, setSelectedAgeClass] = useState('')
  const [selectedSource, setSelectedSource] = useState(
    drillProviders[0]?.uuid || ''
  )
  const [selectedCategories, setSelectedCategories] = useState([])
  const [drillCategories, setDrillCategories] = useState([])
  const [drills, setDrills] = useState([])
  const [meta, setMeta] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(12)
  const [page, setPage] = useState(0)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const checkAndSetAgeClass = useCallback(() => {
    const isTeamAgeClass = ageClasses.some(
      (ageClass) => ageClass.uuid === team.age_class_uuid
    )
    setSelectedAgeClass(isTeamAgeClass ? team.age_class_uuid : 'all')
  }, [ageClasses, team.age_class_uuid])

  useEffect(() => {
    if (ageClasses.length > 0) {
      checkAndSetAgeClass()
    }
  }, [ageClasses])

  const fetchDrills = async (filters) => {
    try {
      let response = null
      const params = {
        ...filters,
        per_page: rowsPerPage,
        page: page + 1,
      }
      if (selectedSource === 'my-team-activities') {
        response = await fetchTeamDrills(team.uuid, params)
      } else if (selectedSource === 'my-favourites') {
        response = await fetchFavouriteDrills(team.uuid, params)
      } else {
        response = await fetchClubDrills(selectedSource, params)
      }
      setDrills(response.data)
      setMeta(response.meta)
    } catch (error) {
      console.error('Error fetching drills:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (drillProviders.length > 0) {
      const validProviders = drillProviders.filter(
        (provider) => provider.uuid !== undefined
      )
      const modifiedProviders = [
        ...validProviders,
        {
          name: <Translate id="drills.my-drills" />,
          uuid: 'my-team-activities',
        },
        {
          name: <Translate id="drills.my-favourites" />,
          uuid: 'my-favourites',
        },
      ]
      setProviders(modifiedProviders)
    }
  }, [drillProviders])

  useEffect(() => {
    if (
      selectedSource &&
      selectedSource !== 'my-team-activities' &&
      selectedSource !== 'my-favourites'
    ) {
      fetchClubAgeClasses(selectedSource)
        .then((res) => {
          setAgeClasses([{ name: 'All', uuid: 'all' }, ...res.data])
        })
        .catch((error) => {
          console.error('Error fetching age classes:', error)
        })
      fetchClubDrillCategories(selectedSource, { include: 'children.children' })
        .then((res) => {
          setDrillCategories(res.data)
        })
        .catch((error) => {
          console.error('Error fetching drill categories:', error)
        })
    }
  }, [selectedSource])

  useEffect(() => {
    setLoading(true)
    const filters = {}
    if (
      selectedSource &&
      selectedSource !== 'my-team-activities' &&
      selectedSource !== 'my-favourites'
    ) {
      if (selectedAgeClass && selectedAgeClass !== 'all') {
        filters['filter[age_class]'] = selectedAgeClass
      }
      if (selectedCategories.length > 0) {
        filters['filter[categories]'] = selectedCategories.join(',')
      }
    }
    if (searchQuery) {
      filters['filter[search]'] = searchQuery
    }
    if (
      selectedSource &&
      (selectedAgeClass !== '' ||
        selectedSource === 'my-favourites' ||
        selectedSource === 'my-team-activities')
    ) {
      fetchDrills(filters)
    }
  }, [
    selectedSource,
    selectedAgeClass,
    selectedCategories,
    searchQuery,
    rowsPerPage,
    page,
  ])

  const handleSearchChange = useCallback(
    debounce((newValue) => {
      setSearchQuery(newValue)
    }, 400),
    []
  )

  const handleSourceChange = (event) => {
    setSelectedSource(event.target.value)
    setSelectedAgeClass(team.age_class_uuid)
    setSelectedCategories([])
    setSearchQuery('')
    setPage(0)
    setRowsPerPage(12)
  }

  const handleAgeClassChange = (event) => {
    setSelectedAgeClass(event.target.value)
    setSearchQuery('')
    setPage(0)
    setRowsPerPage(12)
  }

  const handleCategoryChange = (categories) => {
    setSelectedCategories(categories)
    setPage(0)
    setRowsPerPage(12)
  }

  const handleReset = () => {
    checkAndSetAgeClass()
    setSelectedCategories([])
    setSearchQuery('')
    setPage(0)
    setRowsPerPage(12)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleFavoriteToggle = (drill) => {
    updateFavoriteDrill(drill.uuid, !drill.favorite)
      .then(() => {
        setDrills((prevDrills) =>
          prevDrills.map((d) =>
            d.uuid === drill.uuid ? { ...d, favorite: !drill.favorite } : d
          )
        )
      })
      .catch((error) => {
        console.error('Error toggling favorite status:', error)
      })
  }

  const openCategoriesDialog = () => {
    setIsDialogOpen(true)
  }

  const closeCategoriesDialog = () => {
    setIsDialogOpen(false)
  }

  const handleDuplicateDrill = (uuid) => {
    duplicateDrill(uuid, team.id)
      .then((res) => {
        const resData = res.data
        if (resData && resData.uuid) {
          const editRoute = DRILL_EDIT_ROUTE.populateURLParams({
            drillUuid: resData.uuid,
          })
          const path = `/team/${+team.id}/season/${+seasonId}${editRoute}`
          history.push(path)
        }
      })
      .catch((error) => {
        toast.error(<Translate id="errors.drills.duplicate" />)
        console.error('Error duplicating drill:', error)
      })
  }

  return (
    <>
      <Box className={classes.container}>
        {isDialogOpen && (
          <CategoriesDialog
            isOpen={isDialogOpen}
            onClose={closeCategoriesDialog}
            categories={drillCategories}
            initialSelectedCategories={selectedCategories}
            onCategoryChange={handleCategoryChange}
          />
        )}
        <Box className={classes.filtersContainer}>
          <Source
            selectedValue={selectedSource}
            options={providers}
            onChange={handleSourceChange}
            label={<Translate id="drills.source" />}
            labelId="source-label"
          />
          {selectedSource !== 'my-team-activities' &&
            selectedSource !== 'my-favourites' && (
              <>
                <Source
                  selectedValue={selectedAgeClass}
                  options={ageClasses}
                  onChange={handleAgeClassChange}
                  label={<Translate id="team.age-class" />}
                  labelId="age-class-label"
                />
                <Button variant="contained" onClick={openCategoriesDialog}>
                  <Translate id="articles.categories" />
                </Button>
                <Button onClick={handleReset}>
                  <Translate id="button.reset" />
                </Button>
              </>
            )}
        </Box>
        <Box className={classes.searchBarContainer}>
          <SearchBar
            value={searchQuery}
            onChange={handleSearchChange}
            onCancelSearch={() => {
              setSearchQuery('')
              handleSearchChange('')
            }}
            className={classes.searchBar}
          />
          <TablePagination
            labelRowsPerPage={<Translate id="drills.rows-perpage" />}
            component="div"
            count={meta.total}
            page={page}
            className={classes.tablePagination}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[12, 24, 36]}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  component: MenuPaper,
                },
              },
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={3}>
          {drills.length > 0 ? (
            drills.map((drill) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={drill.uuid}>
                <DrillCard
                  drill={drill}
                  onFavoriteToggle={() => handleFavoriteToggle(drill)}
                  onDuplicate={() => handleDuplicateDrill(drill.uuid)}
                  withPhaseMenu={withPhaseMenu}
                  showAddToTrainingButton={showAddToTrainingButton}
                  language={language}
                  onPhaseSelect={onPhaseSelect}
                  showDrillActions={showDrillActions}
                />
              </Grid>
            ))
          ) : (
            <NotFound />
          )}
        </Grid>
      )}
    </>
  )
}

ActivitiesContainer.defaultProps = {
  withPhaseMenu: false,
  showAddToTrainingButton: false,
  showDrillActions: false,
}

ActivitiesContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    drill_providers: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    uuid: PropTypes.string.isRequired,
    age_class_uuid: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  withPhaseMenu: PropTypes.bool,
  showAddToTrainingButton: PropTypes.bool,
  onPhaseSelect: PropTypes.func.isRequired,
  showDrillActions: PropTypes.bool,
}

export default compose(
  withRouter,
  connect(({ auth, team }) => ({
    team,
    language: auth.user.language,
  }))
)(ActivitiesContainer)
