import React, {useState} from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import {
  TextField,
  Grid,
  Button,
  InputAdornment,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
} from '@material-ui/core'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import AccountCircle from '@material-ui/icons/AccountCircle'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  formatCostumDate,
  formatCostumTime,
  concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat,
  checkIsAfterDateTime,
  formatDateTimeToUtcDateTimeWithUserTimezone,
  formatLocalDateTimeToUserTimezoneDateTime,
  formatDateTimeToServer
} from 'utils/formatDate'
import EventNote from '@material-ui/icons/EventNote'
import DatePicker from 'components/inputes/DatePicker'
import TimePicker from 'components/inputes/TimePicker'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { connect } from 'react-redux'
import InfoIcon from '@material-ui/icons/Info'
import UserActionDialog from './UserActionDialog'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'
import { MATCH_TYPES } from "constants/match";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-adornedEnd': {
      marginRight: '0',
    },
  },
  dateTimeContainer: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: '#757575',
    },
  },
  pr1: {
    paddingRight: '0.25rem',
  },
  pl1: {
    paddingLeft: '0.25rem',
  },
  [theme.breakpoints.down('sm')]: {
    pr1: {
      paddingRight: '0',
    },
    pl1: {
      paddingLeft: '0',
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  formGroup: {
    '& .MuiFormLabel-root': {
      marginBottom: theme.spacing(1),
    },
  },
  infoIcon:{
    position: 'absolute',
    right: 25,
    top: 20,
  },
  select: {
    paddingRight: 0,
  },
}))
// This small component is used to render Meeting and end time error with respect to start date time
const getDateTimeTranslatedError = (translationKey) => {
  return (
    <Typography variant="caption" color="error">
      <Translate id={translationKey} />
    </Typography>
  )
}

const TrainingAndGame = ({
  trainingAndGame,
  createTraining,
  updateTraining,
  scheduleTrainings,
  createMatch,
  createEvent,
  updateEvent,
  updateMatch,
  duplicateData,
  calenderDateSelected,
  calenderDate,
  isDuplicate,
  dateFormat,
  isTrainingSeries,
  team,
  timezone,
}) => {
  const [intensity, changeIntensity] = useState(duplicateData?.intensity)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const openTrainingSeriesDialog = () => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const classes = useStyles()

  const getEventDefaultDateTimeInUserTimezone =()=>{
    const nowDate = calenderDateSelected ? calenderDate : new Date();

    nowDate.setMinutes(
      calenderDateSelected ? nowDate.getMinutes() : nowDate.getMinutes() + 30
    )

    const momentDateTimeInUserTimezone = formatLocalDateTimeToUserTimezoneDateTime(
      nowDate,
      timezone
    )
    const jsDateTime = new Date(momentDateTimeInUserTimezone);

    return jsDateTime
  }

  const [trainingAndGameData, changeFormData] = React.useState({
    match_type: duplicateData?.type || 'league',
    location: '',
    opponent: '',
    description: '',
    status: 'draft',
    type: 'all',
    ...duplicateData,
    date: duplicateData?.date || getEventDefaultDateTimeInUserTimezone(),
    start_date: getEventDefaultDateTimeInUserTimezone(),
    end_date:  getEventDefaultDateTimeInUserTimezone(),
    day_of_week: '-1',
    time: getEventDefaultDateTimeInUserTimezone(),
    street: duplicateData?.street || '',
    city: duplicateData?.city || '',
    country: duplicateData?.country || '',
    postal_code: duplicateData?.postal_code || '',
    lat: duplicateData?.lat || '',
    lng: duplicateData?.lng || '',
    state: duplicateData?.state || '',
    trainingFocus:duplicateData?.training_focus || (duplicateData?.trainingFocus) || '',
    home:duplicateData ? duplicateData.home : 1,
    intensity: duplicateData?.intensity || 0,
    start_time: duplicateData?.start_time || getEventDefaultDateTimeInUserTimezone(),
    meeting_time: duplicateData?.start_time || duplicateData?.meeting_time || null,
    end_time: duplicateData?.end_time || null,
    availability_cutoff_time: duplicateData?.availability_cutoff_time,
  })

  const [cutoffTime, setCutoffTime] = useState(
    duplicateData ? trainingAndGameData.availability_cutoff_time : team.availability_cutoff_time
  )
  const [cutoffTimeOptions, setCutoffTimeOptions] = useState([0, 6, 12, 24])
  const [dateTimeError, setDateTimeError] = useState(null)

  const handleTimeChange = (time) => {
    changeFormData({ ...trainingAndGameData, time })
    changeFormData({ ...trainingAndGameData, date:time })
  }

  const handleDateChange = (date) => {
    changeFormData({ ...trainingAndGameData, date })
  }
  const handleStartDateChange = (date) => {
    changeFormData({ ...trainingAndGameData, start_date: date })
  }
  const handleEndDateChange = (date) => {
    changeFormData({ ...trainingAndGameData, end_date: date })
  }
  const handleStartTimeChange = (time) => {
    changeFormData({ ...trainingAndGameData, start_time:time })
    changeFormData({ ...trainingAndGameData, meeting_time:time })
  }
  const handleEndTimeChange = (time) => {
    changeFormData({ ...trainingAndGameData, end_time: time})
  }
  const onChangeRating = newRating => {
    changeIntensity(newRating)
    changeFormData({ ...trainingAndGameData, intensity:newRating })
  }
  const handleTextBox = (event) => {
    const {
      target: { name, value },
    } = { ...event }
    changeFormData({
      ...trainingAndGameData,
      [name]: value,
      [`error_${name}`]: value === '' || value === null || value === undefined,
    })
  }
  const handleMatchHome = newMatchHome => {
    changeFormData({
      ...trainingAndGameData,
      home: parseInt(newMatchHome, 10),
    })
  }

  const newTraining = () => {
    const { id, date, location, status, type, trainingFocus,street,city,country,postal_code:postalCode,lat,lng,state,additionalInfo,end_time:endTime, availability_cutoff_time } = { ...trainingAndGameData }

    createTraining({
      date: formatDateTimeToServer(date),
      location,
      status,
      type,
      id,
      duplicate: !!id,
      training_focus:trainingFocus,
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      intensity: intensity ? intensity.toFixed(2) : 0.0,
      additional_info: additionalInfo || '',
      end_time: endTime ? formatDateTimeToServer(endTime) : null,
      availability_cutoff_time: cutoffTime
    })
  }
  const updateTrainingOnEventsPage = (applyOn) => {
    const {
      id,
      date,
      location,
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      trainingFocus,
      additionalInfo,
      end_time:endTime
    } = { ...trainingAndGameData }

    updateTraining(id,{
      date: formatDateTimeToServer(date),
      location,
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      training_focus:trainingFocus,
      intensity: intensity ? intensity.toFixed(2) : 0.0,
      additional_info: additionalInfo || '',
      end_time:  endTime ? formatDateTimeToServer(endTime) : null,
      availability_cutoff_time: cutoffTime,
    },
    { apply_on: applyOn }
    )};

  const scheduleOnlyTrainings = () => {
    const {
      start_date: startDate,
      end_date: endDate,
      day_of_week: dayOfWeek,
      date,
      location,
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      trainingFocus,
      additionalInfo,
      end_time:endTime,
    } = { ...trainingAndGameData }
    scheduleTrainings({
      start_date: formatCostumDate(startDate),
      end_date: formatCostumDate(endDate),
      location,
      day_of_week: dayOfWeek,
      time: formatCostumTime(date),
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      training_focus:trainingFocus,
      intensity: intensity ? intensity.toFixed(2) : 0.0,
      additional_info: additionalInfo || '',
      end_time: endTime ? formatDateTimeToServer(endTime) : null,
      availability_cutoff_time: cutoffTime
    })
  }

  // Validate Match date times and opponent required field
  const validateMatchData = (tempMatchData)=>{

    if (!tempMatchData.opponent || tempMatchData.opponent === '') {
      changeFormData({
        ...trainingAndGameData,
        [`error_opponent`]:
        tempMatchData.opponent === '' || tempMatchData.opponent === null || tempMatchData.opponent === undefined,
      })

      return false
    }

    // Check if meeting time is after start time
    if (
      checkIsAfterDateTime(
        tempMatchData.meeting_datetime,
        tempMatchData.start_datetime
      )
    ) {
      setDateTimeError('matches.meeting-time-error')

      return false
    }

    // Check if end time is before start time
    if (
      checkIsAfterDateTime(
        tempMatchData.start_datetime,
        tempMatchData.end_datetime,
      )
    ) {
      setDateTimeError('matches.end-time-error')

      return false
    }

    return true
  }

  // Prepare match data for create and update match
  const prepareMatchData = (data)=>{
    const {
      date: startDateTime,
      location,
      match_type:tempMatchType,
      opponent,
      street,
      city,
      country,
      postal_code:postalCode,
      lat,
      lng,
      state,
      home,
      meeting_time:meetingDateTime,
      end_time:endDateTime,
      additionalInfo,
    } = { ...data }

    const tempData = {
      type: tempMatchType,
      start_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(startDateTime,timezone),
      home,
      opponent,
      location,
      postal_code:postalCode,
      country,
      state,
      street,
      city,
      lat,
      lng,
      meeting_datetime: meetingDateTime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            startDateTime,
            meetingDateTime,
            timezone
          )
        : null,
      end_datetime: endDateTime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            startDateTime,
            endDateTime,
            timezone
          )
        : null,
      details: additionalInfo || '',
      availability_cutoff_time: cutoffTime,
    }

    if (!validateMatchData(tempData)) {
      return false
    }

    return tempData

  }
  const newMatch = () => {
    const tempMatchData = prepareMatchData(trainingAndGameData)

    if (tempMatchData) {
      createMatch(tempMatchData)
    }
  }
  const updateMatchOnEventsPage = () => {
    const tempMatchData = prepareMatchData(trainingAndGameData)

    if (tempMatchData) {
      updateMatch(trainingAndGameData.uuid, tempMatchData)
    }
  }

  const [matchType, setMatchType] = React.useState(
    duplicateData && duplicateData.type ? duplicateData.type : 'league'
  )

  const matchTypeChange = (event) => {
    trainingAndGameData.match_type = event.target.value
    setMatchType(event.target.value)
  }



  // Validate Event date times field
  const validateEventData = (tempEventData)=>{
    // Check if end time is before start time
    if (
      checkIsAfterDateTime(
        tempEventData.start_datetime,
        tempEventData.end_datetime,
      )
    ) {
      setDateTimeError('matches.end-time-error')

      return false
    }

    if (!tempEventData.name || tempEventData.name === '') {
      changeFormData({
        ...trainingAndGameData,
        [`error_description`]: tempEventData.name === '' || tempEventData.name === null || tempEventData.name === undefined,
      })

      return false
    }

    return true
  }

  // Prepare Event data for create and update Event
const prepareEventData = (data)=>{
  const {
    id,
    date: startDateTime,
    location,
    description,
    street,
    city,
    country,
    postal_code:postalCode,
    lat,
    lng,
    state,
    end_time:endDateTime,
    additionalInfo,
  } = { ...data }

  const tempData = {
    uuid: id,
    start_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(startDateTime, timezone),
    name: description,
    location,
    postal_code:postalCode,
    country,
    state,
    street,
    city,
    lat,
    lng,
    end_datetime: endDateTime
      ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
          startDateTime,
          endDateTime,
          timezone
        )
      : null,
    details: additionalInfo || '',
    availability_cutoff_time: cutoffTime,
  }

  if (!validateEventData(tempData)) {
    return false
  }

  return tempData

}

  const newEvent = () => {
    const tempEventData = prepareEventData(trainingAndGameData)

    if (tempEventData) {
      createEvent(tempEventData)
    }
  }

  const existingEvent = () => {
    const tempEventData = prepareEventData(trainingAndGameData)

    if (tempEventData) {
      updateEvent(trainingAndGameData.uuid, tempEventData)
     }
  }
  const handleCutoffTimeChange = (event) => {
    const {value} = event.target
    setCutoffTime(value)
  }
  const SelectDays = () => {
    return (
      <Grid item xs={12} md={6} className={classes.pr1}>
        <FormControl variant="outlined" margin="dense">
          <InputLabel id="day_of_week-outlined_label">
            <Translate id="day-name.day" />
          </InputLabel>
          <Select
            labelId="day_of_week-label"
            id="day_of_week-outlined"
            value={trainingAndGameData.day_of_week}
            onChange={handleTextBox}
            name="day_of_week"
            label={<Translate id="day-name.day" />}
          >
            <MenuItem value="-1">
              <Translate id="day-name.select-day" />
            </MenuItem>
            <MenuItem value="1">
              <Translate id="day-name.monday" />
            </MenuItem>
            <MenuItem value="2">
              <Translate id="day-name.tuesday" />
            </MenuItem>
            <MenuItem value="3">
              <Translate id="day-name.wednesday" />
            </MenuItem>
            <MenuItem value="4">
              <Translate id="day-name.thursday" />
            </MenuItem>
            <MenuItem value="5">
              <Translate id="day-name.friday" />
            </MenuItem>
            <MenuItem value="6">
              <Translate id="day-name.saturday" />
            </MenuItem>
            <MenuItem value="7">
              <Translate id="day-name.sunday" />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    )
  }

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <FormGroup className={classes.formGroup}>
          {trainingAndGame === TRAININGS_GAME.game ? (
            <FormLabel>
              {' '}
              <Translate id="events.general-information" />
            </FormLabel>
          ) : trainingAndGame === TRAININGS_GAME.event ? (
            <FormLabel>
              <Translate id="events.general-information" />{' '}
            </FormLabel>
          ) : trainingAndGame === TRAININGS_GAME.multiTraining ? (
            <FormLabel>
              <Translate id="events.plan-training-schedule-text" />{' '}
            </FormLabel>
          ) : (
            <></>
          )}
          {trainingAndGame === TRAININGS_GAME.game && (
            <Grid container>
              <Grid item xs={12}>
                  <FormControl component="fieldset" margin='dense'>
                    <Translate>
                      {({ translate }) => (
                        <RadioGroup
                          row
                          aria-label="MatchStatus"
                          onChange={(e) => handleMatchHome(e.target.value)}
                          name="MatchStatus"
                          value={trainingAndGameData.home}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio color="primary" />}
                            label={translate('matches.home-game')}
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio color="primary" />}
                            label={translate('matches.away-game')}
                          />
                        </RadioGroup>
                      )}
                    </Translate>
                  </FormControl>
              </Grid>
          </Grid>
          )}
          <Grid container className={classes.dateTimeContainer}>
            {trainingAndGameData &&
            trainingAndGame === TRAININGS_GAME.multiTraining ? (
              <>
                <Grid container className={classes.dateTimeContainer}>
                  {trainingAndGame === TRAININGS_GAME.multiTraining && (
                    <>
                      <Grid item xs={12} md={6} className={classes.pr1}>
                        <DatePicker
                          required
                          label={<Translate id="form.from" />}
                          selectedValue={trainingAndGameData.start_date}
                          onDateChange={handleStartDateChange}
                          isEditMode={!!duplicateData}
                          dateFormat={dateFormat}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.pl1}>
                        <DatePicker
                          required
                          label={<Translate id="form.to" />}
                          selectedValue={trainingAndGameData.end_date}
                          onDateChange={handleEndDateChange}
                          isEditMode={!!duplicateData}
                          dateFormat={dateFormat}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <SelectDays />
              </>
            ) : (
              <Grid item xs={12} md={12}>
                <DatePicker
                  required
                  label={<Translate id="training.date" />}
                  selectedValue={trainingAndGameData.date}
                  onDateChange={handleDateChange}
                  isEditMode={!!duplicateData}
                  dateFormat={dateFormat}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              md={trainingAndGame === TRAININGS_GAME.multiTraining || trainingAndGame === TRAININGS_GAME.event ? 6 : 12}
              className={
                trainingAndGame === TRAININGS_GAME.multiTraining
                  ? classes.pl1
                  : ''
              }
            >
                <TimePicker
                  selectedValue={trainingAndGame === TRAININGS_GAME.multiTraining ? trainingAndGameData.time : trainingAndGameData.date }
                  onDateChange={
                    trainingAndGame === TRAININGS_GAME.multiTraining || trainingAndGame === TRAININGS_GAME.event
                      ? handleTimeChange
                      : handleDateChange
                  }
                  label={<Translate id={(trainingAndGame === TRAININGS_GAME.game) ? "matches.kickoff-time" : "training.time"} />}
                  isEditMode={!!duplicateData}
                />
            </Grid>
            {trainingAndGame === TRAININGS_GAME.event && (
              <Grid item xs={12} md={6} className={classes.pl1} >
                <TimePicker
                  selectedValue={trainingAndGameData.end_time}
                  onDateChange={handleEndTimeChange}
                  label={<Translate id="matches.end-time" />}
                  isEditMode={!!trainingAndGameData.end_time}
                />
              </Grid>
            )}
            <Grid container>
              {(trainingAndGame !== TRAININGS_GAME.event) && (
                <Translate>
                  {({ translate }) => (
                    <Grid
                      item
                      xs={12}
                      md={
                        trainingAndGame === TRAININGS_GAME.game
                          ? 6
                          : 12
                      }
                      className={
                        trainingAndGame === TRAININGS_GAME.game
                          ? classes.pr1
                          : ''
                      }
                    >
                      <PlacesSearchField
                        error={trainingAndGameData.error_location}
                        helperText={
                          trainingAndGameData.error_location
                            ? translate('events.validation.location')
                            : ''
                        }
                        defaultValue={trainingAndGameData.location}
                        savedValue={trainingAndGameData.location}
                        onChange={handleTextBox}
                        changeFormData={changeFormData}
                        eventData={trainingAndGameData}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Translate>
              )}
              {(trainingAndGame === TRAININGS_GAME.training || trainingAndGame === TRAININGS_GAME.multiTraining)&& (
                <Translate>
                  {({ translate }) => (
                    <Grid item xs={12}>
                      <TextField
                        defaultValue={trainingAndGameData.trainingFocus}
                        label={translate('form.training-focus')}
                        name="trainingFocus"
                        margin="dense"
                        variant="outlined"
                        onChange={handleTextBox}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <FilterCenterFocusIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography component="legend" style={{color:"gray"}}>
                          {translate('training.intensity')}
                          </Typography>
                          <Rating
                            size="medium"
                            value={trainingAndGameData.intensity}
                            precision={0.5}
                            onChange={(e, v) => onChangeRating(v)}
                          />
                          {intensity > 0 && (
                              <Button color="secondary" size="small" variant="outlined" style={{float:"right"}}
                                className={classes.resetLink}
                                onClick={() => onChangeRating(0.0)}
                              >
                                Reset <Translate id="training.intensity" />
                              </Button>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            selectedValue={trainingAndGameData.end_time}
                            onDateChange={handleEndTimeChange}
                            label={<Translate id="matches.end-time" />}
                            isEditMode={!!trainingAndGameData.end_time}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Translate>
              )}
              {trainingAndGame === TRAININGS_GAME.game && (
                <Translate>
                  {({ translate }) => (
                    <Grid item xs={12} md={6} className={classes.pl1}>
                      <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        defaultValue={trainingAndGameData.opponent}
                        error={trainingAndGameData.error_opponent}
                        helperText={
                          trainingAndGameData.error_opponent
                            ? translate('events.validation.opponent')
                            : ''
                        }
                        label={translate('matches.opponent')}
                        name="opponent"
                        margin="dense"
                        variant="outlined"
                        onChange={handleTextBox}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Translate>
              )}
              {trainingAndGame === TRAININGS_GAME.game &&(
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={
                      classes.pl1
                    }
                  >
                    <TimePicker
                      selectedValue={trainingAndGameData.meeting_time}
                      onDateChange={handleStartTimeChange}
                      label={<Translate id="matches.meeting-time" />}
                      isEditMode={!!trainingAndGameData.meeting_time}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={
                      classes.pl1
                    }
                  >
                    <TimePicker
                      selectedValue={trainingAndGameData.end_time}
                      onDateChange={handleEndTimeChange}
                      label={<Translate id="matches.match-end-time" />}
                      isEditMode={!!trainingAndGameData.end_time}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {trainingAndGame === TRAININGS_GAME.game && (
                <>
                  <FormControl variant="outlined" margin="dense">
                    <InputLabel id="match-type-label">
                      <Translate id="matches.match-type" />
                    </InputLabel>
                    <Select
                      labelId="match-type-label"
                      id="match-type"
                      value={matchType}
                      onChange={matchTypeChange}
                      label={<Translate id="matches.match-type" />}
                    >
                      {MATCH_TYPES.map(element=>
                        <MenuItem key={element.type} value={element.type}>
                          {element.title}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
            {trainingAndGame === TRAININGS_GAME.event && (
              <>
                <Translate>
                  {({ translate }) => (
                    <Grid item xs={12}>
                      <PlacesSearchField
                        error={trainingAndGameData.error_location}
                        helperText={
                          trainingAndGameData.error_location
                            ? translate('events.validation.location')
                            : ''
                        }
                        defaultValue={trainingAndGameData.location}
                        savedValue={trainingAndGameData.location}
                        onChange={handleTextBox}
                        changeFormData={changeFormData}
                        eventData={trainingAndGameData}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Translate>
                <Translate>
                  {({ translate }) => (
                    <Grid item xs={12}>
                      <TextField
                        error={trainingAndGameData.error_description}
                        helperText={
                          trainingAndGameData.error_description
                            ? translate('events.validation.description')
                            : ''
                        }
                        fullWidth
                        defaultValue={trainingAndGameData.description}
                        variant="outlined"
                        margin="dense"
                        label={translate('events.event-name')}
                        name="description"
                        size="small"
                        onChange={handleTextBox}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventNote />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Translate>
              </>
            )}
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="dense"
              >
                <InputLabel id="cutoff-time-label"><Translate id="player.event-response.cutoff-time" /></InputLabel>
                <Select
                  value={cutoffTime}
                  onChange={handleCutoffTimeChange}
                  label={<Translate id="player.event-response.cutoff-time" />}
                  className={classes.select}
                  endAdornment={
                    <InputAdornment
                      position="start"
                      className={classes.infoIcon}
                    >
                      <Tooltip
                        title={
                          <Translate id="player.event-response.info-message" />
                        }
                        placement="bottom-end"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  {cutoffTimeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option === 0 ? (
                        <Translate id="player.event-response.disabled" />
                      ) : (
                        <>
                          {option}{' '}
                          <Translate id="player.event-response.hours" />
                        </>
                      )}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <TextField
              name="additionalInfo"
              variant="outlined"
              margin="dense"
              defaultValue={trainingAndGameData.additional_info}
              value={trainingAndGameData.additionalInfo}
              multiline
              rows={4}
              label={<Translate id="matches.additional-info" />}
              onChange={handleTextBox}
              className={classes.textarea}
            />
          </Grid>
        </FormGroup>
        <Grid item xs={12}>{dateTimeError && getDateTimeTranslatedError(dateTimeError)}</Grid>
        <Grid item xs={12} style={{ marginTop: 16, marginBottom: 16 }}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            margin="dense"
            onClick={
              (trainingAndGame === TRAININGS_GAME.training && !duplicateData) ||
              (isDuplicate && trainingAndGame === TRAININGS_GAME.training)
                ? newTraining
                : trainingAndGame === TRAININGS_GAME.training && duplicateData && isTrainingSeries
                ? openTrainingSeriesDialog
                : trainingAndGame === TRAININGS_GAME.training && duplicateData && !isTrainingSeries
                ? () => updateTrainingOnEventsPage('SINGLE')
                : trainingAndGame === TRAININGS_GAME.multiTraining
                ? scheduleOnlyTrainings
                : (trainingAndGame === TRAININGS_GAME.game && !duplicateData) ||
                (isDuplicate && trainingAndGame === TRAININGS_GAME.game)
                ? newMatch
                : trainingAndGame === TRAININGS_GAME.game && duplicateData
                ? updateMatchOnEventsPage
                : !isDuplicate && trainingAndGame === TRAININGS_GAME.event && duplicateData
                ? existingEvent
                : newEvent
            }
            >
             {(trainingAndGame === TRAININGS_GAME.training && !duplicateData) ||
            (isDuplicate && trainingAndGame === TRAININGS_GAME.training) ? (
              <Translate id="button.create-plan-training" />
            ) : trainingAndGame === TRAININGS_GAME.training && duplicateData ? (
              <Translate id="button.update-training" />
            ) : trainingAndGame === TRAININGS_GAME.multiTraining ? (
              <Translate id="button.create-plan-training" />
            ) : (trainingAndGame === TRAININGS_GAME.game && !duplicateData) ||
            (isDuplicate && trainingAndGame === TRAININGS_GAME.game) ? (
              <Translate id="button.create-plan-match" />
            ) : trainingAndGame === TRAININGS_GAME.game && duplicateData ? (
              <Translate id="button.update-match" />
            ) : !isDuplicate && trainingAndGame === TRAININGS_GAME.event && duplicateData ? (
              <Translate id="button.update-event" />
            ) : (
              <Translate id="button.create-event" />
            )}
          </Button>

          <UserActionDialog
            open={isDialogOpen}
            onClose={handleClose}
            onActionOne={() => updateTrainingOnEventsPage('SINGLE')}
            onActionTwo={() => updateTrainingOnEventsPage('SINGLE_AND_FOLLOWING')}
            title={<Translate id="training.update" />}
            subTitle={<Translate id="training.update-option" />}
            buttonOneLabel={<Translate id="training.update-this" />}
            buttonTwoLabel={<Translate id="training.update-this-following" />}
          />
        </Grid>
      </form>
    </div>
  )
}

TrainingAndGame.defaultProps = {
  duplicateData: null,
  calenderDate: null,
  calenderDateSelected: false,
  createEvent: () => 0,
  updateEvent: () => 0,
  isDuplicate: false,
}

TrainingAndGame.propTypes = {
  trainingAndGame: PropTypes.string.isRequired,
  scheduleTrainings: PropTypes.func.isRequired,
  createTraining: PropTypes.func.isRequired,
  updateTraining:PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  updateMatch: PropTypes.func.isRequired,
  createEvent: PropTypes.func,
  updateEvent: PropTypes.func,
  duplicateData: PropTypes.shape(),
  calenderDateSelected: PropTypes.bool,
  calenderDate: PROP_TYPES.date,
  isDuplicate: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  timezone:PropTypes.string.isRequired,
  team:PropTypes.shape(
    {
      availability_cutoff_time: PropTypes.number.isRequired
    }
  ).isRequired,
}
export default compose(
  withRouter,
  connect(
  ({ auth, team }) => ({
    team,
    dateFormat: auth.user.datetime_format,
    timezone: auth.user.time_zone,
  }),null
)
)(TrainingAndGame)
