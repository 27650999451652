import React, { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import TrashIcon from 'assets/images/16px/bin@1x.svg'
import EditIcon from 'assets/images/16px/edit@1x.svg'
import PropTypes from 'prop-types'
import AlertDialog from 'components/AlertDialog'
import HistoryIcon from '@material-ui/icons/History'
import urlConstructor from 'utils/urlConstructor'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import EditDialog from './EditDialog'
import MovePlayer from './MovePlayer'
import { TEAM_PLAYER_TRANSFER_HISTORY_ROUTE } from 'constants/routes'

const GridActionsCell = ({
  row: { player, uuid, onUpdate, onDelete, showTransferOption },
  history,
  match: {
    params: { teamId, seasonId },
  },
}) => {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)

  const handleDeleteClick = () => {
    setDeleteIsOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteIsOpen(false)
  }

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(false)
    onDelete()
  }

  const handleEditClick = () => {
    setEditIsOpen(true)
  }

  const handleEditClose = () => {
    setEditIsOpen(false)
  }

  const handlePlayerTransferHistoryIconClick = () => {
    history.push(
      urlConstructor(
        teamId,
        seasonId,
        TEAM_PLAYER_TRANSFER_HISTORY_ROUTE.populateURLParams({
          playerId: uuid,
        })
      )
    )
  }

  return (
    <>
      {deleteIsOpen && (
        <AlertDialog
          open={deleteIsOpen}
          onClose={handleDeleteClose}
          onSuccess={handleDeleteConfirm}
          onCancel={handleDeleteClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {editIsOpen && (
        <EditDialog
          open={editIsOpen}
          onClose={handleEditClose}
          player={player}
          updatePlayer={onUpdate}
        />
      )}

      <Tooltip title={<Translate id="button.edit" />} placement="top" arrow>
        <IconButton onClick={handleEditClick}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={<Translate id="button.delete" />} placement="top" arrow>
        <IconButton onClick={handleDeleteClick}>
          <img src={TrashIcon} alt="" />
        </IconButton>
      </Tooltip>

      <MovePlayer playerUuid={uuid} withTransfer={showTransferOption} />

      {showTransferOption && (
        <Tooltip
          title={<Translate id="button.transfer-history" />}
          placement="top"
          arrow
        >
          <IconButton onClick={handlePlayerTransferHistoryIconClick}>
            <HistoryIcon
              fontSize="small"
              color="disabled"
              style={{ fontSize: '1.2rem' }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

GridActionsCell.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    player: PropTypes.shape({}).isRequired,
    showTransferOption: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.number.isRequired,
      teamId: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default withRouter(GridActionsCell)
