import React from 'react'
import { Translate } from 'react-localize-redux'
import GridActionsCell from './GridActionsCell'
import renderPositionInGrid from './renderPositionInGrid'
import renderStatus from './renderStatus'
import renderAvatar from './renderAvatar'
import renderFullName from './renderFullName'

export default [
  {
    field: 'uuid',
    hide: true
  },
  {
    field: 'avatar',
    headerName: '',
    renderCell: renderAvatar,
    editable: false,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: 'fullName',
    headerName: <Translate id="form.Player-name" />,
    headerClassName: 'primary',
    width: 150,
    editable: false,
    renderCell: renderFullName,
  },
  {
    field: 'number',
    headerName: <Translate id="form.number" />,
    headerAlign: "left",
    headerClassName: 'primary',
    type: 'number',
    align: "left",
    width: 90,
    editable: false,
  },
  {
    field: 'position',
    headerName: <Translate id="form.position" />,
    headerClassName: 'primary',
    width: 100,
    editable: false,
    renderCell: renderPositionInGrid,
  },
  {
    field: 'status',
    headerName: <Translate id="form.status" />,
    editable: false,
    width: 100,
    renderCell: renderStatus,
  },
  {
    field: 'actions',
    headerName: <Translate id="season.actions" />,
    headerClassName: 'primary',
    width: 180,
    headerAlign: "left",
    align: "left",
    renderCell: GridActionsCell,
    disableClickEventBubbling: true,
    sortable: false,
  },
];
