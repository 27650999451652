import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from '@material-ui/core'
import { SubLink } from 'containers'
import hasSubscription from 'utils/hasSubscription'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { LoadingSpinner } from 'components'
import { fetchPlayersStatistics } from '../../api'
import { useStyles } from '../style/styled'
import EnhancedTableHead from '../EnhancedTableHead'
import StatisticsFilter from '../StatisticsFilter/index'
import {
  Avatar,
  Player,
  Table,
  TableContainer,
} from '../style/styledComponents'
import {
  PLAYER_POSITIONS,
  BASIC_STAT_COLUMNS,
  PAID_STAT_COLUMNS,
} from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'
import { BASIC } from 'constants/plans'

function descendingComparator(a, b, orderBy) {
  const getValue = (obj, key) => key.split('.').reduce((o, i) => o?.[i], obj)

  const valueA = getValue(a, orderBy)
  const valueB = getValue(b, orderBy)

  if (valueB < valueA) return -1
  if (valueB > valueA) return 1
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}
const PlayersStatistics = ({
  theme,
  match: {
    params: { seasonId },
  },
  setCsvData
}) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('first_name')
  const [isPerformance] = useState(true)
  const [playerStatistics, setPlayerStatistics] = useState([])
  const [matchType, setMatchType] = useState('')
  const [loading, setLoading] = useState(false)
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells =
    hasSubscription() !== BASIC ? PAID_STAT_COLUMNS : BASIC_STAT_COLUMNS
  const classes = useStyles(theme)

  const fetchStatistics = (params = {}) => {
    fetchPlayersStatistics(seasonId, params).then((res) => {
      setLoading(false)
      setPlayerStatistics(res.data)
      setCsvData(
        res.data.map((datum) => {
            return hasSubscription() !== BASIC
              ? {
                  Player: `${datum.first_name} ${datum.last_name}`,
                  'Uniform#': datum.number,
                  Position: datum.position,
                  'Total Team Trainings': datum.statistics.total_trainings ? datum.statistics.total_trainings : 0,
                  'Player Training Participation': `${
                    datum.statistics.trainings_attended ? datum.statistics.trainings_attended : 0
                  } (${datum.statistics.total_trainings ? datum.statistics.total_trainings && Math.round(
                    (datum.statistics.trainings_attended * 100) / datum.statistics.total_trainings
                  ) : 0}%)`,
                  'Total Matches': datum.statistics.total_matches ? datum.statistics.total_matches : 0,
                  'Match Particiaption': `${datum.statistics.matches_attended ? datum.statistics.matches_attended : 0} (${datum.statistics.total_matches?Math.round(
                    (datum.statistics.matches_attended * 100) / datum.statistics.total_matches
                  ) : 0}%)`,
                  'Total Match Time (Min)': datum.statistics.total_minute_played ? datum.statistics.total_minute_played : 0,
                  'Goal Scored': datum.statistics.goals_scored ? datum.statistics.goals_scored : 0,
                  'Goal Assisted': datum.statistics.goals_assisted ? datum.statistics.goals_assisted : 0,
                  'Yellow Card': datum.statistics.ycard ? datum.statistics.ycard : 0,
                  'Yellow+Red Card': datum.statistics.yrcard ? datum.statistics.yrcard : 0,
                  'Red Card': datum.statistics.rcard ? datum.statistics.rcard : 0,
                }
              : {
                  Player: `${datum.first_name} ${datum.last_name}`,
                  'Uniform#': datum.number,
                  Position: datum.position,
                  'Total Team Trainings': datum.statistics.total_trainings ? datum.statistics.total_trainings : 0,
                  'Player Training Participation': `${
                    datum.statistics.trainings_attended ? datum.statistics.trainings_attended : 0
                  } (${datum.statistics.total_trainings ? Math.round(
                    (datum.statistics.trainings_attended * 100) / datum.statistics.total_trainings
                  ) : 0}%)`,
                }
          })
      )
    })
  }

  const getPercentage = (value, total) => {
    if (total === 0) {
      return ' (NaN)'
    }

    return ` (${Math.round((value * 100) / total)}%)`
  }

  useEffect(() => {
    // When filters are clear and tab loads
    if (!filterDate.startDate && !filterDate.endDate && matchType === '') {
      setLoading(true)
      fetchStatistics()
    }

    // When all filters applied
    if (
      filterDate.startDate &&
      filterDate.endDate &&
      matchType &&
      matchType !== ''
    ) {
      const params = {
        start_date: filterDate.startDate,
        end_date: filterDate.endDate,
        match_type: matchType,
      }
      setLoading(true)
      fetchStatistics(params)
    }
  }, [matchType, filterDate])

  const sortedData = stableSort(playerStatistics, getComparator(order, orderBy))

  return (
    <>
      <Box my={1}>
        <StatisticsFilter
          tab="MATCH"
          matchType={matchType}
          setMatchType={setMatchType}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      </Box>
      {loading && <LoadingSpinner />}
      {!loading && (
        <TableContainer>
          <Table className={classes.table} stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
              isPerformance={isPerformance}
            />

            <TableBody>
              {sortedData.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow hover tabIndex={-1} key={row.uuid}>
                    <TableCell component="th" id={labelId} scope="row">
                      <Player
                        component={SubLink}
                        to={`${TEAM_DASHBOARD_ROUTE}/player/${row.uuid}/overview`}
                      >
                        {row.relationships.avatar ? (
                          <Avatar
                            alt={row.first_name}
                            src={row.relationships.avatar.full_url}
                          />
                        ) : (
                          <Avatar alt={row.first_name} />
                        )}
                        <div>
                          <Typography variant="h4">
                            {`${row.first_name} ${row.last_name}`}
                          </Typography>
                          <Typography noWrap>
                            {
                              PLAYER_POSITIONS.find(
                                (p) => p.value === row.position
                              ).title
                            }
                          </Typography>
                        </div>
                      </Player>
                    </TableCell>
                    <TableCell align="left">{row.number}</TableCell>
                    <TableCell align="left">
                      {row.statistics.total_trainings}
                    </TableCell>
                    <TableCell align="left">
                      {row.statistics.trainings_attended}
                      {getPercentage(
                        row.statistics.trainings_attended,
                        row.statistics.total_trainings
                      )}
                    </TableCell>
                    {hasSubscription() !== BASIC && (
                      <>
                        <TableCell align="left">
                          {row.statistics.total_matches}
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.matches_attended}
                          {getPercentage(
                            row.statistics.matches_attended,
                            row.statistics.total_matches
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.total_minute_played} min
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.goals_scored}
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.goals_assisted}
                        </TableCell>

                        <TableCell align="left">
                          {row.statistics.ycard}
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.yrcard}
                        </TableCell>
                        <TableCell align="left">
                          {row.statistics.rcard}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

PlayersStatistics.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  match: PropTypes.shape().isRequired,
  setCsvData: PropTypes.func.isRequired,
}

export default withRouter(PlayersStatistics)
